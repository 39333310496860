var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticStyle:{"min-height":"45rem"}},[_c('el-table',{attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","width":"100","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"背景图","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('img',{staticStyle:{"height":"3rem","width":"3rem","border-radius":".5rem"},attrs:{"src":_vm._f("tomedia")(s.row.bg)}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
        name:'card-plugin-wifi-tpl-edit',
        query:{
          id:s.row.id,
        }
        })}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.clear(s.row)}}},[_vm._v("清缓存")]),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.del(s.row)}}},[_vm._v("删除")])]}}])},[_c('template',{slot:"header"},[_c('el-button',{on:{"click":function($event){return _vm.$router.push({
        name:'card-plugin-wifi-tpl-edit',
        })}}},[_vm._v("添加模版")])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }